<template>
  <div>
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <a-spin tip="Loading...">
        <a-alert
          message="Info Text"
          description="Info Description Info Description Info Description Info Description"
          type="info"
          closable
        />
      </a-spin>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitAntdSpinExample',
}
</script>
