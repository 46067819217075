<template>
  <div>
    <h5 class="mb-3">
      <strong>Basic</strong>
    </h5>
    <div class="mb-5">
      <div class="d-inline-block mr-4">
        <a-avatar icon="user" />
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar shape="square" icon="user" />
      </div>
    </div>
    <h5 class="mb-3">
      <strong>Type</strong>
    </h5>
    <div class="mb-5">
      <div class="d-inline-block mr-4">
        <a-avatar icon="user" />
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar>U</a-avatar>
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar>USER</a-avatar>
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png" />
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar style="color: #f56a00; background-color: #fde3cf">U</a-avatar>
      </div>
      <div class="d-inline-block mr-4">
        <a-avatar style="background-color: #87d068" icon="user" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'KitAntda-avatarExample',
}
</script>
